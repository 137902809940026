.announcement-wrapper {
  height: calc(100vh - 126px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.announcement-content {
  flex: 1;
  width: 1027px;
  display: flex;
  flex-direction: column;
}

.announcement-title {
  color: rgb(123, 123, 123);
  font-family: 微软雅黑;
  font-size: 30px;
  font-weight: 700;
  margin: 10px 0;
}

.announcement-sub {
  color: rgb(204, 204, 204);
  font-family: 微软雅黑;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.announcement-line {
  width: 100%;
  height: 3px;
  background: rgb(169, 169, 169);
  margin: 20px 0;
}

.announcement-container {
  flex: 1;
  overflow-y: auto;
}

.announcement-container-item {
  margin: 10px 0;
  color: rgb(34, 34, 34);
  font-size: 20px;
  font-weight: 400;
  font-family: 微软雅黑;
  cursor: pointer;
}
