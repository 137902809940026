.header {
  height: 123px;
  border-bottom: 3px solid #009fe9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-title {
  font-size: 32px;
  font-weight: bold;
  color: #009fe9;
}

.link-wrapper {
  margin: 10px 0 0 50px;
}

.link-item {
  margin-right: 20px;
  font-size: 16px;
  text-decoration: none;
  color: rgb(77, 77, 77);
}

.link-item.selected {
  color: #009fe9;
}

.header-date {
  width: 180px;
  margin: 10px 0 0 150px;
}