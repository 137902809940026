.home-nav {
  height: 700px;
  background: url(../asset/1.webp) no-repeat;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-nav-content {
  width: 782px;
  height: 377px;
  background: url(../asset/2.webp) no-repeat;
}

.home-nav-f {
  text-align: center;
  color: rgb(106, 177, 233);
  font-family: Arial;
  font-size: 36px;
  margin-top: 100px;
}

.home-nav-s {
  text-align: center;
  color: rgb(0, 161, 234);
  font-size: 50px;
  margin-top: 20px;
  font-weight: bold;
}

.home-nav-t {
  text-align: center;
  color: rgb(106, 177, 233);
  font-size: 20px;
  margin-top: 20px;
}

.home-section {
  height: 725px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section-l {
  margin-right: 50px;
  width: 430px;
}

.home-section-l-title {
  font-family: 微软雅黑;
  font-size: 30px;
  color: rgb(0, 161, 234);
  font-weight: bold;
  margin-bottom: 15px;
}

.home-section-l-sub {
  font-family: 微软雅黑;
  color: rgb(153, 153, 153);
  font-size: 14px;
  margin-bottom: 25px;
}

.home-section-l-place {
  width: 68px;
  height: 6px;
  background-color: rgb(0, 161, 234);
  margin-bottom: 25px;
}

.home-section-l-text {
  color: rgb(51, 51, 51);
  font-family: "helvetica neue", helvetica, "pingfang sc", "hiragino sans gb", "microsoft yahei", 微软雅黑, arial, sans-serif;
  font-size: 14px;
  line-height: 25px;
}

.home-section-r {
  width: 477px;
  height: 318px;
}

.home-third {
  height: 380px;
  background: url(../asset/4.jpeg) no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-third-f {
  width: 273px;
  height: 144px;
  margin-right: 40px;
  background: url(../asset/5.png) no-repeat;
  background-size: 100% 100%;
}

.home-third-s {
  width: 273px;
  height: 144px;
  background: url(../asset/6.png) no-repeat;
  background-size: 100% 100%;
}