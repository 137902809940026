.home-fourth {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-family: 微软雅黑;
}
.icp-reference {
  color: #a6a6a6;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.icp-reference:hover {
  color: #478fed;
}
.public-security-reference {
  color: #fff;
  font-size: 14px;
  vertical-align: middle;
  cursor: pointer;
}
.public-security-reference:hover {
  color: #478fed;
}