.announcement-wrapper {
  height: calc(100vh - 126px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.announcement-content {
  flex: 1;
  width: 1024px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.announcement-title {
  color: rgb(123, 123, 123);
  font-family: 微软雅黑;
  font-size: 30px;
  font-weight: 700;
  margin: 10px 0;
}

.announcement-sub {
  color: rgb(204, 204, 204);
  font-family: 微软雅黑;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.announcement-line {
  width: 100%;
  height: 3px;
  background: rgb(169, 169, 169);
  margin: 20px 0;
}

.announcement-container {
  flex: 1;
}

.announcement-container-item {
  margin: 10px 0;
  color: rgb(34, 34, 34);
  font-size: 20px;
  font-weight: 400;
  font-family: 微软雅黑;
  cursor: pointer;
}

.document-h {
  text-align: center;
}
.document-p {
  text-indent: 2em;
  font-size: 14px;
  font-family: 微软雅黑;
} 
.document-t {
  text-align: right;
  font-weight: bold;
}

.document-div {
  padding: 0 28px;
}

.margin-10 {
  margin: 10px 0;
}
